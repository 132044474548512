import {Component, OnDestroy, OnInit} from '@angular/core';
import {faAngleDown, faExternalLink, faEye, faPlus} from '@fortawesome/free-solid-svg-icons';
import screenfull from 'screenfull';
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
  extractErrorMessagesFromResponse,
  FeatureType,
  getFirstAvailableUrlForEmployee,
  getStoreId,
  Pageable,
  StoreService,
  YanLoaderService,
  YanToastrService
} from "@app/_shared";
import {Subscription} from "rxjs";
import {Store} from "@app/_shared/model/store/store.model";
import {storeMenuItems} from "@app/_shared/component/shopyan-layout/left-menu/menu";
import {FeatureAction} from "@app/_shared/enumeration/feature-action.enum";
import {UserService} from "@app/_shared/service/user.service";
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {SocketService} from "@app/_shared/service/socket.service";
import {SubscriptionStatus} from "@app/_shared/enumeration/subscription-status.enum";
import {Notification} from "@app/_shared/model/notification.model";
import {NotificationTypeEnum} from "@app/_shared/enumeration/notification-type.enum";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Direction} from "@angular/cdk/bidi";
import {Message} from "@app/_shared/model/message.model";
import {MessageComponent} from "@app/_shared/component/shopyan-header/message/message.component";
import {MatDialog} from "@angular/material/dialog";
import {VersionComponent} from "@app/_shared/component/shopyan-header/version/version.component";

@Component({
  selector: 'app-shopyan-header',
  templateUrl: './shopyan-header.component.html',
  styleUrls: ['./shopyan-header.component.scss']
})
export class ShopyanHeaderComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  stores: Store[];
  filter: string;
  pageable = new Pageable();

  /** Menus features **/
  menuItems = JSON.parse(JSON.stringify(storeMenuItems));
  featureAction = FeatureAction;
  featureType = FeatureType;

  /**  Icons **/
  faAngleDown = faAngleDown;
  faPlus = faPlus;
  faExternalLink = faExternalLink;

  sfull = screenfull;
  userName: string | undefined;
  avatar = 'ab';

  /** Notifications **/
  notifications: Notification[] = [];
  notificationsPageable = new Pageable();
  notificationType = NotificationTypeEnum;
  notifShowMoreBtn: boolean;

  /** Notifications **/
  messages: Message[] = [];
  messagesPageable = new Pageable();
  messagesShowMoreBtn: boolean;

  /** Init counters */
  notifCount = 0;
  messageCount = 0;

  public locales: any = [
    {name: "French", code: "fr", country: "fr"},
    {name: "English", code: "en", country: "gb"},
    {name: "العربية", code: "ar", country: "ma"},
    {name: "Deutsch", code: "de", country: "de"},
    {name: "Español", code: "es", country: "es"},
    {name: "Italiano", code: "it", country: "it"},
    {name: "Portugu\u00EAs", code: "pt", country: "pt"},
    {name: "Polski", code: "pl", country: "pl"}
  ]

  constructor(private keycloakService: KeycloakService,
              private translate: TranslateService,
              private router: Router,
              public storeCacheService: StoreCacheService,
              private storeService: StoreService,
              private userService: UserService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private yanToastrService: YanToastrService,
              private yanLoaderService: YanLoaderService,
              public socketService: SocketService) {
  }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLogged => {
      if (isLogged) {
        this.keycloakService.loadUserProfile().then(profile => {
          this.userName = profile.username;
          this.avatar = this.userName ? this.userName.substring(0, 2) : 'ab';
        });
        this.loadNewVersion();
        this.notificationsPageable.page = 1;
        this.notificationsPageable.size = 6;
        this.messagesPageable.page = 1;
        this.messagesPageable.size = 6;
        this.loadNotifications();
        this.userService.countUnSeenNotification().subscribe(response => {
          this.notifCount = response;
        });
        this.loadMessages();
        this.userService.countUnSeenMessages().subscribe(response => {
          this.messageCount = response;
        });
      } else {
        this.keycloakService.login({
          redirectUri: window.location.origin + this.router.url
        });
      }
    });
    this.keycloakService.getToken().then(token => {
      this.socketService.connect(token);
      this.socketService.rxStomp.watch({destination: "/user/queue/notifs"})
        .subscribe((message) => {
          const notif = JSON.parse(message.body);
          this.notifications.unshift(notif);
          this.notifCount++
          const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
          let sb = this.snackBar.open(this.translate.instant('NOTIFICATION.' + notif.type + '.SNACK_MSG', Object.assign({}, notif.msgArgs.split(','))),
            this.translate.instant('NOTIFICATION.' + notif.type + '.ACTION'), {
              duration: 10000,
              panelClass: ["snackbar-style"],
              direction: dir
            });
          sb.onAction().subscribe(() => {
            this.gotoNotification(notif);
            sb.dismiss();
          });
        });
      this.socketService.rxStomp.watch({destination: "/user/queue/logout"})
        .subscribe((message) => {
          if (JSON.parse(message.body)?.id == this.keycloakService.getKeycloakInstance().sessionId) {
            this.socketService.disconnect().then();
            this.keycloakService.login({
              redirectUri: window.location.origin + '/my-sites'
            });
            return;
          }
        });
      this.socketService.rxStomp.watch({destination: "/user/queue/msgs"})
        .subscribe((message) => {
          const msg = JSON.parse(message.body);
          this.messages.unshift(msg);
          this.messageCount++
        });
    });

    this.loadStores();
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

  /**
   * Show stores menu
   *
   */
  get showStoresMenu(): boolean {
    return window.location.pathname.split(';')[0]?.split('/')[1] === 'store';
  }

  /**
   * Load stores
   */
  loadStores(): void {
    this.yanLoaderService.startLoader('stores-spinner');
    const sub = this.storeService.filterStores(undefined, this.filter, this.pageable).subscribe(response => {
      this.stores = response.data;
      this.yanLoaderService.stopLoader('stores-spinner');
    });
    this.subscription.add(sub);
  }

  /**
   * Go to store
   * @param storeId
   */
  gotoStore(storeId: string) {
    let url = this.router.url.replace(getStoreId(), storeId);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.yanLoaderService.startLoader('main-spinner');
    this.storeCacheService.getStoreCacheData(storeId).subscribe({
      next: data => {
        if (![SubscriptionStatus.PAID, SubscriptionStatus.CANCELING, SubscriptionStatus.PAYMENT_FAILED].includes(data.subscriptionStatus)) {
          this.router.navigateByUrl(`/checkout/${storeId}`).then();
          this.yanLoaderService.stopLoader('main-spinner');
          return;
        }
        this.storeCacheService.initStoreCacheData(data);
        this.storeCacheService.fromStore = true;
        const feature = this.getUrlFeature(url);
        if (data.employee && !data.storePermissions.features.some((value: any) =>
          value.feature == feature && value.actionFeatures.includes(FeatureAction.READ))) {
          if (data.storePermissions.features.length === 0) {
            this.yanToastrService.error(this.translate.instant('COMMON.DONT_HAVE_PERMISSION'));
            this.yanLoaderService.stopLoader('main-spinner');
            return;
          }
          url = getFirstAvailableUrlForEmployee(this.menuItems, data.storePermissions.features, url);
          url = url.replace(':storeID', storeId);
        }
        this.router.navigate([url]).then();
        this.yanLoaderService.stopLoader('main-spinner');
      }, error: error => {
        this.router.navigateByUrl('/my-sites').then();
        this.yanLoaderService.stopLoader('main-spinner');
      }
    });
  }

  getUrlFeature(url: string) {
    let feature = ''
    this.menuItems.every((value: any) => {
      if (value.feature && value.url == url) {
        feature = value.feature;
        return false;
      }
      const subFeature = value.subMenus?.every((value: any) => {
        if (value.url == url) {
          feature = value.feature;
          return false;
        }
        return true;
      });
      if (subFeature) return subFeature;
    });
    return feature;
  }

  loadNotifications(showMore?: boolean) {
    this.yanLoaderService.startLoader('notifs-spinner');
    if(showMore) {
      this.notificationsPageable.page++;
    }
    const sub = this.userService.getNotifications(this.notificationsPageable).subscribe(response => {
      if (showMore) {
        this.notifications = [...this.notifications,...response.data];
      } else {
        this.notifications = [...response.data];
      }
      this.yanLoaderService.stopLoader('notifs-spinner');
      this.notifShowMoreBtn = response.data && response.data.length === this.notificationsPageable.size;
    });
    this.subscription.add(sub);
  }

  loadMessages(showMore?: boolean) {
    this.yanLoaderService.startLoader('messages-spinner');
    if(showMore) {
      this.messagesPageable.page++;
    }
    const sub = this.userService.getMessages(this.messagesPageable).subscribe(response => {
      if (showMore) {
        this.messages = [...this.messages,...response.data];
      } else {
        this.messages = [...response.data];
      }
      this.yanLoaderService.stopLoader('messages-spinner');
      this.messagesShowMoreBtn = response.data && response.data.length === this.messagesPageable.size;
    });
    this.subscription.add(sub);
  }

  gotoNotification(notification: Notification) {
    this.userService.markNotificationAsSeen(notification.id).subscribe(value => {
      notification.seen = true;
    });
    if (!notification.seen) this.notifCount--;
    if (notification.type === NotificationTypeEnum.ORDER) {
      if (this.router.url.includes(notification.storeId)) {
        let url = `/store/${notification.storeId}/orders`;
        if (!notification.disabled) {
          url = url + `/details/${notification.objectId}`;
        } else {
          url = url + `/list`;
        }
        this.router.navigateByUrl(url).then();
        return;
      }
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.yanLoaderService.startLoader('main-spinner');
      this.storeCacheService.getStoreCacheData(notification.storeId).subscribe({
        next: data => {
          if (![SubscriptionStatus.PAID, SubscriptionStatus.CANCELING, SubscriptionStatus.PAYMENT_FAILED].includes(data.subscriptionStatus)) {
            this.router.navigateByUrl(`/checkout/${notification.storeId}`).then();
            this.yanLoaderService.stopLoader('main-spinner');
            return;
          }
          this.storeCacheService.initStoreCacheData(data);
          this.storeCacheService.fromStore = true;
          if (data.employee && !data.storePermissions.features.some((value: any) =>
            value.feature == FeatureType.ORDERS && value.actionFeatures.includes(FeatureAction.READ))) {
            if (data.storePermissions.features.length === 0) {
              this.yanToastrService.error(this.translate.instant('COMMON.DONT_HAVE_PERMISSION'));
              this.yanLoaderService.stopLoader('main-spinner');
              return;
            }
          }
          let url = `/store/${notification.storeId}/orders`;
          if (!notification.disabled) {
            url = url + `/details/${notification.objectId}`;
          } else {
            url = url + `/list`;
          }
          this.router.navigateByUrl(url).then();
          this.yanLoaderService.stopLoader('main-spinner');
        }, error: error => {
          this.router.navigateByUrl('/my-sites').then();
          this.yanLoaderService.stopLoader('main-spinner');
        }
      });
    }
  }

  gotoMsg(data: Message) {
    this.yanLoaderService.startLoader('main-spinner');
    const sub = this.userService.getMessage(data.id).subscribe({
      next: response => {
        if (!data.seen) {
          this.messageCount--;
          data.seen = true;
        }
        const config = {
          width: '900px',
          autoFocus: false,
          data: {
            store: data.msgArgs.split(',')[0],
            date: data.createdDate,
            message: response
          }
        };
        const dialogRef = this.dialog.open(MessageComponent, config);
        this.subscription.add(this.router.events.subscribe(() => {
          dialogRef.close();
        }));
        this.yanLoaderService.stopLoader('main-spinner');
      }, error: error => {
        this.yanToastrService.error(extractErrorMessagesFromResponse(error));
        this.yanLoaderService.stopLoader('main-spinner');
      }
    });
    this.subscription.add(sub);
  }

  loadNewVersion() {
    this.yanLoaderService.startLoader('main-spinner');
    const sub = this.userService.getNewVersion().subscribe({
      next: response => {
        this.yanLoaderService.stopLoader('main-spinner');
        if (!response) return;
        const config = {
          width: '651px',
          autoFocus: false,
          disableClose: true,
          data: response
        };
        const dialogRef = this.dialog.open(VersionComponent, config);
        this.subscription.add(dialogRef.afterClosed().subscribe(result => {
          this.userService.markAsSeenNewVersion().subscribe(() => {
          });
        }));

      }, error: error => {
        this.yanToastrService.error(extractErrorMessagesFromResponse(error));
        this.yanLoaderService.stopLoader('main-spinner');
      }
    });
    this.subscription.add(sub);
  }

  /**
   * Toggle screen
   */
  fullScreenToggle(): void {
    if (screenfull.isEnabled) {
      screenfull.toggle().then();
    }
  }

  /**
   * Get selected local
   */
  get selectedLocale(): any {
    return this.locales.find((locale: any) => locale.code === this.translate.currentLang);
  }

  /**
   * Log out
   */
  logout() {
    this.userService.logout().subscribe();
    this.socketService.disconnect().then();
    this.keycloakService.logout(window.location.origin + '/my-sites').then();
  }

  changeLanguage(lang: string) {
    if(this.translate.currentLang === lang) return;
    this.userService.updateLang(lang).subscribe(() => {
      this.translate.use(lang);
      location.reload();
    });
  }

  now() {
    return new Date();
  }

  ngOnDestroy() {
    this.socketService.disconnect().then();
    this.subscription.unsubscribe();
  }

  protected readonly Object = Object;
  protected readonly faEye = faEye;
}
